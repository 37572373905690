// get_notifications
export const GET_NOTIFICATIONS = 'get_notifications';
export const GET_NOTIFICATIONS_SUCCESSFUL = 'get_notifications_successfull';
export const GET_NOTIFICATIONS_FAILED = 'get_notifications_failed';
export const UPDATE_NOTIFICATIONS_DATA = 'update_notifications_data';

// remove_notification
export const REMOVE_NOTIFICATION = "remove_notification";
export const REMOVE_NOTIFICATION_SUCCESSFUL = "remove_notification_successfull";
export const REMOVE_NOTIFICATION_FAILED = "remove_notification_failed";

// update_notification_seen_status
export const UPDATE_NOTIFICATION_SEEN_STATUS = "update_notification_seen_status";
export const UPDATE_NOTIFICATION_SEEN_STATUS_SUCCESSFUL = "update_notification_seen_status_successfull";
export const UPDATE_NOTIFICATION_SEEN_STATUS_FAILED = "update_notification_seen_status_failed";