import { isArray } from 'lodash';

function buildFormData(formData, data, parentKey) {
  if (isArray(data)) {
    if (data[0] instanceof File) {
      data.forEach((file) => formData.append(parentKey, file));
    } else {
      formData.append(parentKey, JSON.stringify(data));
    }
  } else if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function objectToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export default objectToFormData;
