/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-expressions */
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  GET_NOTIFICATIONS, REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION_SEEN_STATUS
} from '../types/notification';

import { axiosPut, del, get } from '../../ApiConfig';
import { getNotificationsFailed, getNotificationsSuccessful, removeNotificationFailed, removeNotificationSuccessful, updateNotificationSeenStatusFailed, updateNotificationSeenStatusSuccessful } from './actions';

// get_notifications
const getNotifications = ({ page }) => {
  return get('/admin/options/user/notification', { params: { page, limit: 50 } }).then(
    (data) => data.data
  );
};
function* GetNotifications({ payload }) {
  try {
    const { page = 1 } = yield select(
      ({ notification }) => notification.notifications
    );
    var currentPage = payload?.page || page;
    const { totalPages } = yield select(
      ({ notification }) => notification.notifications
    );
    if (!payload?.page && totalPages && totalPages < page) {
      yield put(
        getNotificationsSuccessful({
          ...payload,
          data: [],
          isPageEnd: true,
        })
      );
      return;
    }
    const response = yield call(getNotifications, { page: currentPage });
    yield put(
      getNotificationsSuccessful({
        data: response.data,
        page: currentPage + 1,
        totalPages: Math.ceil(response.count / 50),
      })
    );
  } catch (error) {
    yield put(
      getNotificationsFailed({
        error,
        page: currentPage,
        data: [],
      })
    );
  }
}

//remove_notification
const removeNotificationAsync = ({ id }) => {
  return del(`/admin/options/user/notification/${id}`, {}, { notifyError: true });
};
function* RemoveNotification({ payload: { onSuccess, onError, ...payload } }) {
  try {
    const response = yield call(removeNotificationAsync, payload);
    yield put(removeNotificationSuccessful(response));
    onSuccess(response)
  } catch (error) {
    yield put(removeNotificationFailed(error));
    onError?.(error)
  }
}

// update_notification_seen_status
const updateNotificationSeenStatusAsync = ({ id, ...payload }) => {
  return axiosPut(
    `/admin/options/user/notification/${id}`, payload, {}, { notifyError: true })
};
function* UpdateNotificationSeenStatus({ payload: { id, ...payload } }) {
  try {
    const response = yield call(updateNotificationSeenStatusAsync, { id, ...payload });
    yield put(updateNotificationSeenStatusSuccessful(response));
  } catch (error) {
    yield put(updateNotificationSeenStatusFailed(error));
  }
}

export function* watchGetAllNotification() {
  yield takeLatest(GET_NOTIFICATIONS, GetNotifications);
  yield takeLatest(REMOVE_NOTIFICATION, RemoveNotification);
  yield takeLatest(UPDATE_NOTIFICATION_SEEN_STATUS, UpdateNotificationSeenStatus);
}

export default function* rootSaga() {
  yield all([fork(watchGetAllNotification)]);
}
