import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_SUCCESSFUL,
  REMOVE_NOTIFICATION,
  REMOVE_NOTIFICATION_FAILED,
  REMOVE_NOTIFICATION_SUCCESSFUL,
  UPDATE_NOTIFICATIONS_DATA,
  UPDATE_NOTIFICATION_SEEN_STATUS,
  UPDATE_NOTIFICATION_SEEN_STATUS_FAILED,
  UPDATE_NOTIFICATION_SEEN_STATUS_SUCCESSFUL
} from '../types/notification';

// get_notifications
export const getAdminNotifications = (payload) => {
  return {
    type: GET_NOTIFICATIONS,
    payload,
  };
};
export const getNotificationsSuccessful = (payload) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESSFUL,
    payload,
  };
};
export const getNotificationsFailed = (payload) => {
  return {
    type: GET_NOTIFICATIONS_FAILED,
    payload,
  };
};
export const updateNotificationsData = (payload) => {
  return {
    type: UPDATE_NOTIFICATIONS_DATA,
    payload,
  };
};



// remove_notification
export const removeNotification = (payload) => {
  return {
    type: REMOVE_NOTIFICATION,
    payload,
  };
};
export const removeNotificationSuccessful = (payload) => {
  return {
    type: REMOVE_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};
export const removeNotificationFailed = (payload) => {
  return {
    type: REMOVE_NOTIFICATION_FAILED,
    payload,
  };
};

// update_notification_seen_status
export const updateNotificationSeenStatus = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_SEEN_STATUS,
    payload,
  };
};
export const updateNotificationSeenStatusSuccessful = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_SEEN_STATUS_SUCCESSFUL,
    payload,
  };
};
export const updateNotificationSeenStatusFailed = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_SEEN_STATUS_FAILED,
    payload,
  };
};