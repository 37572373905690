import { uniqBy } from "lodash";
import { getNotificationData } from '../../containers/navs/notification';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_SUCCESSFUL,
  REMOVE_NOTIFICATION,
  REMOVE_NOTIFICATION_FAILED,
  REMOVE_NOTIFICATION_SUCCESSFUL,
  UPDATE_NOTIFICATIONS_DATA,
  UPDATE_NOTIFICATION_SEEN_STATUS,
  UPDATE_NOTIFICATION_SEEN_STATUS_FAILED,
  UPDATE_NOTIFICATION_SEEN_STATUS_SUCCESSFUL
} from '../types/notification';

const INIT_STATE = {
  notifications: {
    loading: false,
    error: null,
    page: 1,
    hasUnreadNotification: false,
    data: [],
  },
  removeNotificationAction: {
    status: "idle",
    error: null,
    data: {}
  }, updateNotificationSeenStatusAction: {
    status: "idle",
    error: null,
    data: {}
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // ------ get_notifications ------
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: action.payload?.page === 1 ? [] : state.notifications.data,
          isPageEnd:
            action.payload?.page === 1 ? false : state.notifications.isPageEnd,
          totalPages:
            action.payload?.page === 1 ? null : state.notifications.totalPages,
          loading: true,
          error: null,
        },
      };
    case GET_NOTIFICATIONS_SUCCESSFUL:
      return {
        ...state,
        notifications: {
          loading: false,
          error: null,
          ...action.payload,
          data: uniqBy([
            ...(action.payload?.data?.map(
              (data) => getNotificationData(data)[data.type] || data
            ) || []),
            ...state.notifications.data], id => id)
        },
      };
    case GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.payload,
          loading: false,
          data: [...state.notifications.data, ...(action.payload?.data || [])],
        },
      };
    case UPDATE_NOTIFICATIONS_DATA:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          hasUnreadNotification: action.payload.data.hasUnreadNotification,
          data: uniqBy([...(action.payload.data || []), ...state.notifications.data], id => id)
        },
      };
    //------ remove_notification ------
    case REMOVE_NOTIFICATION:
      return {
        ...state, removeNotificationAction: {
          status: 'submitting',
          error: null,
          data: action.payload
        }
      }
    case REMOVE_NOTIFICATION_SUCCESSFUL:
      return {
        ...state,
        removeNotificationAction: {
          status: 'submitted',
          error: null,
          data: action.payload
        }
      }
    case REMOVE_NOTIFICATION_FAILED:
      return {
        ...state,
        removeNotificationAction: {
          status: 'failed',
          error: action.payload,
          data: {}
        }
      }
    //------ update_notification_seen_status ------
    case UPDATE_NOTIFICATION_SEEN_STATUS:
      return {
        ...state,
        updateNotificationSeenStatusAction: {
          status: 'submitting',
          error: null,
          data: action.payload
        }
      }
    case UPDATE_NOTIFICATION_SEEN_STATUS_SUCCESSFUL:
      return {
        ...state,
        updateNotificationSeenStatusAction: {
          status: 'submitted',
          error: null,
          data: action.payload
        }
      }
    case UPDATE_NOTIFICATION_SEEN_STATUS_FAILED:
      return {
        ...state,
        updateNotificationSeenStatusAction: {
          status: 'failed',
          error: action.payload,
          data: {}
        }
      }
    default:
      return {
        ...state,
      };
  }
};
